import React from 'react'
import "./style.css"
import Talent from '../../component/Talents'

 
function MainTesttimonial() {
  return (<>
    <div id="testimonail" className='MainTestimonailContainer'>
      <Talent/>
      
    </div>
      
  </>

  )
}

export default MainTesttimonial